<template>
  <v-container>
    <v-row justify="center" no-gutters d-flex>
      <v-col class="text-center" cols="12" lg="1">
        <v-img
          :src="require('@/assets/logo-sign.png')"
          height="100"
          width="100"
          class="mx-auto"
        ></v-img>
      </v-col>
      <v-col class="text-center py-6 pl-5" cols="12" lg="4">
        <div class="text-h4 text-sm-h3 title">BPO Corporation</div>
      </v-col>
    </v-row>
    <v-col class="text-h4 mt-6 text-center ">
      Ценообразование сервисной платы.</v-col
    >
    <v-col class="text-justify text-h7">
      <p>
        Обшество с ограниченной ответственностью Бюро путешествий «Ольга» (ООО
        «БПО»)- коммерческая организация, действующая на основании устава.
      </p>
      <p>
        Основным направлением ООО «БПО» является деятельность, непосредственно
        направленная на достижение миссии, ради которой ООО «БПО» была создана,
        а именно: предоставить возможность свободного передвижения людям по
        всему миру; оказывать услуги, которые дарят людям радость общения,
        делают их жизнь ярче и интереснее; предоставить каждому клиенту высокий
        уровень сервиса, широкий ассортимент и конкурентные цены; партнерам -
        строить долгосрочные, надежные и открытые отношения; персоналу-
        постоянно развиваться, участвуя с командой единомышленников в жизни
        компании и получая достойную оценку личного вклада.
      </p>
    </v-col>
    <v-col class="text-center text-h7">
      <p>
        Именно поэтому при оказании услуг пассажирам мы
        неукоснительно придерживаемся следующих принципов:
      </p>
    </v-col>
    <v-col class="text-h7">
      <ol>
        <li>
          Услуги оказываются исключительно обученными и высококлассными
          специалистами. Сотрудники в обязательном порядке проходят экзамен на
          соответствие занимаемой должности в рамках регулярной аттестации.
          Средний стаж работы агента - 7 лет.
        </li>
        <li>
          Быстрое обслуживание. Мы понимаем, насколько ценно время наших
          клиентов, и строго регламентируем время на осуществление той или иной
          услуги для пассажира.
        </li>
        <li>
          1 минута на подбор оптимального маршрута на самолете, поезде или
          автобусе, 3 минуты на покупку. Благодаря наличию большого количества
          ресурсов для поиска авиабилетов, ж/д билетов, билетов на автобусы
          дальнего следования, мы имеем возможность быстро подобрать оптимальный
          вариант перевозки пассажира, а благодаря профессионализму наших
          сотрудников, оформление билетов среднем занимает не более 3-х минут.
        </li>
        <li>
          Наличие эксклюзивных билетов. Благодаря огромному опыту работы и
          наличию уникальных возможностей по поиску билетов, мы осуществляем
          комплекс услуг по реализации билетов, которые сложно, а порой и
          невозможнонайти в свободной продаже;
        </li>
        <li>
          Более удобная форма расчетов. Мы заботимся об удобстве нашихклиентов,
          поэтому предлагаем различные варианты оплаты - наличные, карта,
          QR-код;
        </li>
        <li>
          Билеты оформляются согласно правилам перевозчика с предоставлением
          всей документации (в том числе маршрут-квитанции, кассового чека и
          тд.), что имеет принципиальное значение для подотчётных пассажиров.
        </li>
        <li>
          Гарантия качества. Вслучае внесения некорректных данных,
          ответственность берет на себя компания. Ни один наш пассажир не был
          отстранен или не допущен к полету по вине нашей компании.
        </li>
        <li>
          Экономия времени иусилий. Мы берем на себя все заботы клиента: подбор
          оптимального маршрута (в том числе сложных составных маршрутов),
          консультация по дополнительным опциям, консультация по оптимальному
          провозу багажа конкретно для каждого пассажира в максимально
          оптимальные сроки.
        </li>
        <li>
          Гарантия безопасности покупки услуги. 20-ти летний опыт работы
          позволяет гарантировать каждому клиенту безопасную сделку, а
          современная система видеонаблюдения позволяет клиенту чувствовать себя
          комфортно и безопасно.
        </li>
        <li>
          Оказываем услугу «под ключ» вне зависимости от сложности запроса
          клиентов.
        </li>
        <li>
          У нас широкий выбор авиабилетов, а также возможность их обмена или
          возврата.
        </li>
        <li>
          Мы дорожим своей репутацией, и делаем всё чтобы наши клиенты остались
          довольны. Если Вы не улетели понашей вине, мы оплатим Вам новый билет
          или вернем Ваши деньги.
        </li>
      </ol>
      <p>
        Доходы, получаемые организацией, складываются из вознаграждения,
        получаемого от Авиакомпаний (в среднем 1 рубль за билет) и суммы сбора,
        утвержденных руководством ООО «БІІО». Как правило, сумма сбора не
        зависит от цены билета, за исключением случаев, когда стоимость билета
        высока ивлечет за собой высокиеплатежи банку за эквайринг. Так как
        авиакомпаниям в большинстве случаев государство компенсирует затраты за
        выполнение рейсов и дотирует перевозчиков,то цена билета может быть
        занижена. Для коммерческих компаний таких привилегий нет, и мы
        устанавливаем такой сбор, который позволяет нам компенсировать наши
        затраты.
      </p>
      <p>
        В среднем, сбор зависит от наличия или отсутствия мест в системе и
        времени, затраченного на предварительное бронирование и мониторинг
        систем бронирования для выписки билета с целью выявления дополнительных
        мест, открываемых Авиакомпаниями.
      </p>
      <p>
        Доходом агентства при реализации продуктов поставщика,
        является разница между ценой поставщика и итоговой ценой,
        по которой данный продукт был приобретен покупателем.
      </p>
    </v-col>
    <v-col class="text-h7 mt-6 text-center">
      Доходы, получаемые ООО «БПО» распределяются следующим образом:</v-col
    >
    <v-col class="text-h7" style="margin-left: 50px;">
      - 35% выплата заработной платы;<br />
      - 25% оплата аренды;<br />
      - 10% оплата налогов;<br />
      - 10% депозиты авиакомпаниям;<br />
      - 7% расход эквайринга;<br />
      - 6% оплата бухгалтерских услуг; - 1.5% оплата интернета и связи;<br />
    </v-col>
    <v-col class="text-h7">
      <p>
        А также на оплату прочих хозяйственных расходов: на выплату заработной
        платы административно-управленческому и обслуживающему персоналу; на
        содержание и эксплуатацию основных средств; на оплату коммунальных
        услуг.
      </p>
    </v-col>
    <v-col class="text-center text-h7 font-weight-bold">
      В пакет услуги «продажа билета» входит:
    </v-col>
    <v-col class="text-h7">
      <ol>
        <li>
          Первичная консультацияо наличии мест на нужном направлении.
        </li>
        <li>
          Уточнение вариантов о аэропорте вылета (прилета), с багажом/без
          багажа, времени вылета/прилета.
        </li>
        <li>
          Поиск минимальной стоимости билета по выбранному направлению с учетом
          заданных характеристик (осуществляется одновременно у всех
          поставщиков- партнеров нашей компании)
        </li>
        <li>
          Консультации по габаритам ручной клади,багажа, сверх нормативного
          багажа.
        </li>
        <li>
          Условия въезда/выезда для разных категорий граждан.
        </li>
        <li>
          Соблюдение минимального стыковочного времени при подборе сложного маршрута.
        </li>
        <li>
          Возможность путешествия с животными.
        </li>
        <li>
          Условия провоза оружия.
        </li>
        <li>
          Оформление страховых полисов (по требованию пассажира).
        </li>
        <li>
          Консультации по приобретению «делового проездного».
        </li>
        <li>
          Консультации по оформлению билетов «специальной» категории пассажиров
          - многодетные семьи, пенсионеры, военные, студенты и т.д.
        </li>
        <li>
          Оформление гостиничных услуг (по желанию пассажира).
        </li>
        <li>
          Консультации по оформлению трансферов (по желанию пассажира).
        </li>
        <li>
          Консультации по оформлению билетов «специальной» категории пассажиров
          многодетные семьи, пенсионеры, военные, студенты и т.д.
        </li>
        <li>
          Оформление гостиничных услуг (по желаниюпассажира).
        </li>
        <li>
          Консультации по оформлению трансферов (по желанию пассажира).
        </li>
      </ol>
    </v-col>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
p {
  text-indent: 50px;
}
ol {
  margin-left: 30px;
  margin-bottom: 30px;
}
</style>
